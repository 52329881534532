import moment from 'moment';
import { Context } from '..';
import { formatValue, titleCase } from '@frontend/shared-utils';
import { AccountLink } from './state';

export const onInitializeOvermind = () => {
  /* context.state.payment.earningsColumns = [
    {
      dataKey: 'merchant_name',
      label: 'Merchant',
      width: 3,
      sortable: true,
    },
    {
      dataKey: 'earnings_month',
      label: 'Earnings Month',
      width: 2,
      justifyContent: 'right',
      sortable: true,
    },
    {
      dataKey: 'payment_status',
      label: 'Status',
      width: 2,
      fontColor: cellData =>
        cellData.toLowerCase().includes('overdue')
          ? 'red'
          : cellData.toLowerCase().includes('payment') || cellData.toLowerCase().includes('paid')
          ? 'green'
          : '',
      justifyContent: 'right',
      sortable: false,
      cellRender: ({ cellData }) =>
        `<div style={{ whiteSpace: 'normal', textAlign: 'right' }}>{cellData}</div>`,
    },
    {
      dataKey: 'publisher_earnings',
      label: 'Amount',
      width: 1,
      justifyContent: 'right',
      sortable: true,
    },
  ];

  context.state.payment.payoutsColumns = [
    {
      dataKey: 'payout_date',
      label: 'Payout Date',
      width: 4,
      tooltip: () => ({
        text: 'View breakdown by Merchant',
        size: 200,
        fixedSize: true,
        noTail: true,
      }),
      cellRender: ({ cellData }) => context.actions.date.formatDisplayDate(cellData),
      sortable: true,
    },
    {
      dataKey: 'transfer_status',
      label: 'Status',
      width: 2,
      fontColor: cellData => (cellData.toLowerCase() === 'paid' ? 'green' : ''),
      justifyContent: 'right',
      sortable: true,
    },
    {
      dataKey: 'payout_amount',
      label: 'Amount',
      width: 1,
      justifyContent: 'right',
      sortable: true,
    },
  ]; */
};

export const checkIfBillingUser = (context: Context) => {
  if (context.state.session.sessionData && context.state.auth.accessList) {
    const orgPerms = context.state.auth.accessList.find(
      (org) => org.orgId === context.state.session.sessionData.orgId,
    );
    return orgPerms && orgPerms.isBillingUser;
  }
  return false;
};

export const checkIfStripeSetupComplete = (context: Context) => {
  if (context.state.session.sessionData && context.state.auth.accessList) {
    const orgPerms = context.state.auth.accessList.find(
      (org) => org.orgId === context.state.session.sessionData.orgId,
    );
    return orgPerms && orgPerms.stripeSetupComplete;
  }
  return false;
};

export const getAccountLink = async (context: Context, publisherId: number) => {
  await context.effects.api
    .get(`/api/v0/billing/account_link/${publisherId}/`)
    .then((resp: any) => {
      const data = resp.data.data[0];
      context.actions.payment.setAccountLink({
        currently_due: data.currently_due,
        disabled_reason: data.disabled_reason,
        pending_verification: data.pending_verification,
        payouts_enabled: data.payouts_enabled,
        account_setup_complete: data.account_setup_complete,
        errors: data.errors,
        url: data.url,
        current_deadline: data.current_deadline,
      });
    })
    .catch((err: any) => {
      context.actions.payment.setAccountLink(null);
      console.warn('Unable to get account link - ', err);
    });
};

export const setAccountLink = (context: Context, value: AccountLink | null) => {
  context.state.payment.accountLink = value;
};

export const getPaymentSettingsInfo = (
  context: Context,
  publisherId: number,
) => {
  return context.effects.api.get(
    `/api/v0/billing/account/payment_setting/${publisherId}/`,
  );
};

export const showAlertIcon = (context: Context, accountLink: AccountLink) => {
  return (
    !context.state.session.sessionData.isAdmin &&
    ['unconnected', 'disabled', 'descriptive-error'].includes(
      context.actions.payment.getStatus(accountLink),
    )
  );
};

export const getStatus = (context: Context, link: AccountLink) => {
  if (!Object.keys(link).length) return '';

  return !link.payouts_enabled &&
    !link.currently_due &&
    (link.disabled_reason === 'requirements.pending_verification' ||
      link.pending_verification)
    ? 'pending'
    : link.account_setup_complete && !link.payouts_enabled
    ? 'disabled'
    : link.currently_due || link.errors
    ? 'descriptive-error'
    : !link.account_setup_complete && !link.payouts_enabled
    ? 'unconnected'
    : 'success';
};

export const getEarnings = (
  context: Context,
  { publisherId, params }: { publisherId: number; params: any },
) => {
  const url = `/api/v1/billing/earnings/${publisherId}/`;
  return context.effects.api.get(url, params);
};

export const getEarningsBreakdown = (
  context: Context,
  { publisherId, params }: { publisherId: number; params: any },
) => {
  const url = `/api/v1/billing/earnings/${publisherId}/transfers/`;
  return context.effects.api.get(url, params);
};

export const getPayouts = (
  context: Context,
  { publisherId, params }: { publisherId: number; params: any },
) => {
  const url = `/api/v1/billing/payouts/${publisherId}/`;
  return context.effects.api.get(url, params);
};

export const getPayoutsBreakdown = (
  context: Context,
  { publisherId, params }: { publisherId: number; params: any },
) => {
  const url = `/api/v1/billing/payouts/${publisherId}/transfers/`;
  return context.effects.api.get(url, params);
};

export const getPublisherInfo = (context: Context, params: object) => {
  const url = '/api/v0/publishers/';
  return context.effects.api.get(url, params);
};

export const setInstantPayInfo = (context: Context, value: object) => {
  context.state.payment.instantPayInfo = value;
};

export const transferBtnClicked = (context: Context, value: boolean) => {
  context.state.payment.transferClicked = value;
};

export const setUpInstantPay = async (
  context: Context,
  publisherId: number,
) => {
  const url = `/api/v0/billing/instant_pay/${publisherId}/`;
  let data = {};
  context.state.payment.loadingInstantPayInfo = true;
  await context.effects.api
    .get(url, {})
    .then((resp: any) => {
      data = resp.data.data[0];
    })
    .catch(() => {
      data = {};
    })
    .finally(() => {
      context.state.payment.loadingInstantPayInfo = false;
    });
  setInstantPayInfo(context, data);
};

export const transferNow = (
  context: Context,
  { publisherId, params }: { publisherId: number; params: object },
) => {
  const url = `/api/v0/billing/instant_pay/payout/${publisherId}/`;
  return context.effects.api.post(url, params);
};

export const requestInstantPayAccess = (
  context: Context,
  publisherId: number,
) => {
  return context.effects.api.put(
    `/api/v0/publishers/${publisherId}/instant_pay/request/`,
  );
};

export const requestIpLimitIncrease = (
  context: Context,
  publisherId: number,
) => {
  return context.effects.api.put(
    `/api/v0/billing/instant_pay/${publisherId}/limit_increase/request/`,
  );
};

const formatMonthOption = (dateObj: any) => {
  return {
    label: dateObj.format('MMMM YYYY'),
    params: {
      month: parseInt(dateObj.format('M')),
      year: parseInt(dateObj.format('YYYY')),
    },
  };
};

export const populateDateSelector = (context: Context, data: { view: any }) => {
  const monthDropdownOptions = [{ label: 'Lifetime', params: {} }];

  let monthDropdownItems = 6;
  let dateObj, monthOption;
  while (monthDropdownItems >= 0) {
    dateObj = moment().subtract(monthDropdownItems, 'months');
    monthDropdownItems--;
    monthOption = formatMonthOption(dateObj);
    monthDropdownOptions.push(monthOption);
  }

  if (data?.view == 'payouts') {
    dateObj = moment().add(1, 'months');
    monthOption = formatMonthOption(dateObj);
    monthDropdownOptions.push(monthOption);
  }
  return monthDropdownOptions.reverse();
};

export const formatEarningsData = (context: Context, data: { data: any }) => {
  return data.data.map((earning: any, index: any) => {
    if (
      earning?.earnings_month &&
      earning?.payout_date &&
      earning?.payout_status &&
      earning.payout_status.toLowerCase() === 'to be paid'
    ) {
      const earningsMonth = moment(earning.earnings_month, 'MMMM YYYY');
      if (
        earningsMonth.year() === moment().year() &&
        earningsMonth.month() === moment().month()
      ) {
        earning.payout_status = 'Not yet due (current month)';
      } else if (earningsMonth.month() === moment().month() + 1) {
        earning.payout_status = 'Not yet due (future month)';
      } else {
        const formatted_payout_date = moment(earning.payout_date).format(
          'MMM YYYY',
        );
        earning.payout_status = `Due Net-90: ${formatted_payout_date}`;
      }
    }

    return {
      key: index,
      ...earning,
      earnings_month: earning.earnings_month,
      publisher_earnings: formatValue(earning.publisher_earnings, 'currency'),
    };
  });
};

export const formatEarningsBreakdownData = (context: Context, data: any[]) => {
  return data.map((payout: any, index: any) => ({
    ...payout,
    key: index,
    merchant_name: titleCase(payout.merchant_name),
    due_date: payout.due_date,
    payout_status: payout.payout_status,
    amount: formatValue(payout.amount, 'currency'),
  }));
};

export const formatPayoutsData = (
  context: Context,
  data: { data: any; date: any; totals: any },
) => {
  const payouts = data.data.map((data: any, index: any) => {
    // TODO examine formatDisplayDate warning in console
    return {
      key: index,
      type: data.type,
      amount: formatValue(data.amount, 'currency'),
      status: data.status,
      transfer_initiated_date: data.transfer_initiated_date,
      payout_date: data.payout_date,
    };
  });

  const { month, year } = data.date;
  const dateLabel =
    month && year
      ? ` (${context.actions.date.formatMonthYear({
          year: year,
          month: month - 1,
        })})`
      : '';

  const feesLabel =
    data.totals?.total_payouts_gross &&
    data.totals?.total_payouts &&
    data.totals.total_payouts_gross !== data.totals.total_payouts
      ? ` (${formatValue(
          data.totals?.total_payouts_gross,
          'currency',
        )} before fees)`
      : '';

  const payoutTotalCard = {
    title: `Total Amount Paid${dateLabel}`,
    value: formatValue(data.totals.total_payouts, 'currency'),
    label: feesLabel,
    subLabel: feesLabel,
    tooltip: 'The total amount paid to you after fees (if any apply).',
  };

  const expectedPayoutCard = {
    title: 'In Transit',
    value: formatValue(data.totals.in_transit_amount, 'currency'),
    label: '',
    tooltip:
      'Payments that are In Transit typically arrive in 3-5 business days.',
  };

  return {
    payouts,
    cardData: [payoutTotalCard, expectedPayoutCard],
  };
};

export const formatPayoutsBreakdownData = (context: Context, data: any[]) => {
  return data.map((payout: any, index: any) => ({
    ...payout,
    key: index,
    payout_date: payout.payout_date,
    earnings_month: payout.earnings_month,
    amount: formatValue(payout.amount_gross, 'currency'),
    merchant_name: titleCase(payout.merchant_name),
    transaction_fee: payout.transaction_fee,
  }));
};

export const formatEarningsCardData = (
  context: Context,
  data: {
    date: any;
    totals: any;
    showInstantPayLink: any;
    isLifetime: boolean;
    lifetimeEarnings: any;
    isStripeBilling: boolean;
    redirectInstantPay: (args: any) => any;
  },
) => {
  const { date } = data;
  const dateLabel = date
    ? date.year && date.month
      ? `In ${context.actions.date.formatMonthYear({
          year: date.year,
          month: date.month - 1,
        })}`
      : 'In Lifetime'
    : ' ';

  const cardLabels = [
    {
      className: data.showInstantPayLink
        ? 'w-full md:w-2/3 lg:w-3/4'
        : 'w-full',
      cards: [
        {
          title: 'Total Amount Earned',
          label: dateLabel,
          subLabel: '',
          value: data.totals.total_earnings,
        },
        ...(data.isStripeBilling
          ? [
              {
                title: 'Total Amount Paid',
                label: 'Paid',
                subLabel:
                  data.totals?.total_payouts_in_transit &&
                  data.totals?.total_payouts_in_transit > 0
                    ? `(${formatValue(
                        data.totals.total_payouts_in_transit,
                        'currency',
                      )} in transit)`
                    : '',
                value: data.totals.total_payouts_paid,
              },
              {
                title: 'Pending Earnings',
                label: 'Not Due Yet',
                value: data.totals.total_outstanding,
              },
            ]
          : []),
      ],
    },
    ...(data.showInstantPayLink
      ? [
          {
            className: 'w-full md:w-1/3 lg:w-1/4 min-w-[240px]',
            cards: [
              {
                title: 'Instant Pay Eligible Earnings',
                label: 'Not Due Yet',
                subLabel: 'Instant Pay',
                value: data.totals.total_outstanding_select_brands,
                tooltip:
                  'This represents your earnings with Howl Select brands, which are eligible for Instant Pay.',
                subLabelFunc: data.redirectInstantPay,
              },
            ],
          },
        ]
      : []),
  ];

  cardLabels.forEach((cardGroup) => {
    cardGroup.cards.forEach((card) => {
      card.value = formatValue(card.value, 'currency');
    });
  });
  return cardLabels;
};

export const getBonusProgress = (context: Context, publisherId: number) => {
  const url = `/api/v0/billing/goal/${publisherId}/progress/`;
  if (publisherId === 4361 && process.env.NODE_ENV === 'production') {
    // For DEMO purposes. Creator MyBeautyTips.
    return new Promise((resolve) => {
      return resolve({
        data: {
          info: {
            revision: '83f279a22a96ff717dd1aac5844de2cf00af8d60',
            error: false,
            status: 200,
            env: 'release',
          },
          data: [
            {
              active: true,
              progress_usd: '56354.52',
              goal_id: 2,
              name: '2022 Holiday Bonus',
              description: 'The 2022 Holiday Bonus',
              datetime_start: '2022-11-21T08:00:00Z',
              datetime_end: '2023-01-02T08:00:00Z',
              goal_usd: '100000.00',
              bonus_amount_usd: '3000.00',
              datetime_achieved: null,
              opted_in: true,
            },
          ],
        },
      });
    });
  }
  return context.effects.api.get(url);
};

export const goalOptIn = (
  context: Context,
  { publisherId, params }: { publisherId: number; params: any },
) => {
  const url = `/api/v0/billing/goal/${publisherId}/opt_in/`;
  return context.effects.api.post(url, params);
};

export const setBonusInfo = (context: Context, value: object) => {
  context.state.payment.bonusInfo = value;
};

export const formatBonusInfo = (context: Context, data: { data: any }) => {
  const info = data.data;
  return {
    active: info.active,
    goalName: info.name,
    goalDescription: info.description,
    goalStartDate:
      info.datetime_start != null ? moment.utc(info.datetime_start) : null,
    goalEndDate:
      info.datetime_end != null ? moment.utc(info.datetime_end) : null,
    goalReached: info.datetime_achieved != null,
    goalProgress: parseInt(info.progress_usd as string),
    goalAmount:
      info.goal_usd != null ? parseInt(info.goal_usd as string) : null,
    bonusAmount: info.bonus_amount_usd
      ? parseInt(info.bonus_amount_usd as string)
      : null,
    optedIn: info.opted_in,
    goalId: info.goal_id,
  };
};

export const getBonusDetails = async (
  context: Context,
  publisherId: number,
) => {
  await getBonusProgress(context, publisherId)
    .then((res: any) => {
      const data = res.data.data[0];
      const details = formatBonusInfo(context, { data: data });
      setBonusInfo(context, details);
    })
    .catch(() => {
      setBonusInfo(context, { error: true });
    });
};

export const getMockData = () => {
  return {
    earningsRes: {
      pub_id: 1000,
      date: {
        invoice_month: 5,
        invoice_year: 2020,
      },
      totals: {
        // Totals power the header cards on the earnings tab
        total_earnings: '7740.69',
        total_payouts: '20.00',
        total_outstanding: '7720.69',
      },
      data: [
        {
          earnings_month: 'May 2020',
          publisher_payouts: '20.00',
          payout_status: 'To Be Paid',
          payout_date: 'August 30, 2020',
          publisher_earnings: '7740.69',
        },
      ],
      showInstantPayLink: true,
      isLifetime: false,
      lifetimeEarnings: '7740',
    },
    payoutsRes: {
      pub_id: 1000,
      date: {
        month: 12,
        year: 2020,
      },
      totals: {
        total_payouts: '149167.37',
        next_expected_payout: '16388.20',
      },
      data: [
        {
          transfer_initiated_date: 'April 16th, 2022',
          payout_date: 'April 18th, 2022',
          type: 'Net 90',
          status: 'Pending',
          amount: '149167.37',
        },
        {
          transfer_initiated_date: 'April 13th, 2022',
          payout_date: 'April 15th, 2022',
          type: 'Instant Pay',
          status: 'Paid',
          amount: '200.00',
        },
      ],
    },
    payoutsBreakdownRes: {
      pub_id: 1000,
      date: '2022-04-25',
      data: [
        {
          transfer_id: 'tr_1KsZzLDwyej14ObvArfilyT0',
          payout_date: 'April 25th, 2022',
          merchant_name: 'campaign invoicing test',
          type: 'Instant Pay',
          earnings_month: 'September 2021',
          amount: '1805.17',
          amount_gross: '1870.64',
          transaction_fee: '3.5',
        },
        {
          transfer_id: 'tr_1KsWpkDwyej14ObvGaqtj0ni',
          payout_date: 'April 25th, 2022',
          merchant_name: 'invoice test merch',
          type: 'Instant Pay',
          earnings_month: 'August 2021',
          amount: '318.45',
          amount_gross: '330.00',
          transaction_fee: '3.5',
        },
        {
          transfer_id: 'tr_1KsRWnDwyej14ObvSZXU4jgT',
          payout_date: 'April 25th, 2022',
          merchant_name: 'invoice test merch',
          type: 'Instant Pay',
          earnings_month: 'July 2021',
          amount: '283.71',
          amount_gross: '294.00',
          transaction_fee: '3.5',
        },
        {
          transfer_id: 'tr_1KsZzLDwyej14ObvArfilyT0',
          payout_date: 'April 25th, 2022',
          merchant_name: 'Verishop',
          type: 'Instant Pay',
          earnings_month: 'September 2021',
          amount: '5.17',
          amount_gross: '5.36',
          transaction_fee: '3.5',
        },
      ],
      total: {
        amount_gross: '2500.00',
        amount: '2412.50',
        transaction_fees: '87.50',
      },
    },
    earningsBreakdownRes: {
      pub_id: 1000,
      date: {
        year: 2022,
        month: 5,
      },
      data: [
        {
          merchant_name: 'merch1',
          amount: '250.00',
          publisher_payouts: '0.00',
          payout_status: 'To Be Paid',
          due_date: 'June 1st, 2022',
        },
        {
          merchant_name: 'merch2',
          amount: '75.00',
          due_date: 'June 1st, 2022',
          publisher_payouts: '0.00',
          payout_status: 'To Be Paid',
        },
      ],
      total: {
        amount: '325.00',
      },
    },
    bonusProgressRes: {
      noneActive: {
        active: false,
        name: null,
        description: null,
        goalStartDate: null,
        goalEndDate: null,
        goalAmount: null,
        goalProgress: '0.00',
        bonusAmount: null,
        goalReached: false,
        optedIn: false,
        goalId: null,
      },
      upcoming: {
        active: true,
        name: 'Holiday Bonus Bash',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam gravida nec urna sed ultricies. Nulla tincidunt metus at est interdum iaculis. Etiam rhoncus tellus diam, non blandit odio tempor vel. Mauris enim nisi, tempus non cursus a, suscipit ac leo. Curabitur id tortor et felis pharetra consectetur. Donec ultrices vestibulum imperdiet.',
        goalStartDate: '2032-11-21T00:00:00Z',
        goalEndDate: '2033-01-01T00:00:00Z',
        goalAmount: '8409.90',
        goalProgress: '0.00',
        bonusAmount: '42.05',
        goalReached: false,
        optedIn: false,
        goalId: 1,
      },
      inProgress: {
        active: true,
        name: 'Holiday Bonus Bash',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam gravida nec urna sed ultricies. Nulla tincidunt metus at est interdum iaculis. Etiam rhoncus tellus diam, non blandit odio tempor vel. Mauris enim nisi, tempus non cursus a, suscipit ac leo. Curabitur id tortor et felis pharetra consectetur. Donec ultrices vestibulum imperdiet.',
        goalStartDate: '2022-10-20:00:00Z',
        goalEndDate: '2032-01-01T00:00:00Z',
        goalAmount: '8409.90',
        goalProgress: '2551.76',
        bonusAmount: '42.05',
        goalReached: false,
        optedIn: true,
        goalId: 1,
      },
      inProgressNotParticipating: {
        active: true,
        name: 'Holiday Bonus Bash',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam gravida nec urna sed ultricies. Nulla tincidunt metus at est interdum iaculis. Etiam rhoncus tellus diam, non blandit odio tempor vel. Mauris enim nisi, tempus non cursus a, suscipit ac leo. Curabitur id tortor et felis pharetra consectetur. Donec ultrices vestibulum imperdiet.',
        goalStartDate: '2022-10-20:00:00Z',
        goalEndDate: '2032-01-01T00:00:00Z',
        goalAmount: null,
        goalProgress: '0.00',
        bonusAmount: null,
        goalReached: false,
        optedIn: false,
        goalId: 1,
      },
      inProgressNoEnd: {
        active: true,
        name: 'Holiday Bonus Bash',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam gravida nec urna sed ultricies. Nulla tincidunt metus at est interdum iaculis. Etiam rhoncus tellus diam, non blandit odio tempor vel. Mauris enim nisi, tempus non cursus a, suscipit ac leo. Curabitur id tortor et felis pharetra consectetur. Donec ultrices vestibulum imperdiet.',
        goalStartDate: '2022-10-20:00:00Z',
        goalEndDate: null,
        goalAmount: '8409.90',
        goalProgress: '2551.76',
        bonusAmount: '42.05',
        goalReached: false,
        optedIn: false,
        goalId: 1,
      },
      completedGoalReached: {
        active: true,
        name: 'Holiday Bonus Bash',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam gravida nec urna sed ultricies. Nulla tincidunt metus at est interdum iaculis. Etiam rhoncus tellus diam, non blandit odio tempor vel. Mauris enim nisi, tempus non cursus a, suscipit ac leo. Curabitur id tortor et felis pharetra consectetur. Donec ultrices vestibulum imperdiet.',
        goalStartDate: '2022-10-01:00:00Z',
        goalEndDate: '2022-10-19T00:00:00Z',
        goalAmount: '8409.90',
        goalProgress: '14321.90',
        bonusAmount: '42.05',
        goalReached: true,
        optedIn: true,
        goalId: 1,
      },
      completedGoalNotReached: {
        active: true,
        name: 'Holiday Bonus Bash',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam gravida nec urna sed ultricies. Nulla tincidunt metus at est interdum iaculis. Etiam rhoncus tellus diam, non blandit odio tempor vel. Mauris enim nisi, tempus non cursus a, suscipit ac leo. Curabitur id tortor et felis pharetra consectetur. Donec ultrices vestibulum imperdiet.',
        goalStartDate: '2022-10-01:00:00Z',
        goalEndDate: '2022-10-19T00:00:00Z',
        goalAmount: '8409.90',
        goalProgress: '7522.90',
        bonusAmount: '42.05',
        goalReached: false,
        optedIn: true,
        goalId: 1,
      },
      completedDidNotParticipate: {
        active: true,
        name: 'Holiday Bonus Bash',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam gravida nec urna sed ultricies. Nulla tincidunt metus at est interdum iaculis. Etiam rhoncus tellus diam, non blandit odio tempor vel. Mauris enim nisi, tempus non cursus a, suscipit ac leo. Curabitur id tortor et felis pharetra consectetur. Donec ultrices vestibulum imperdiet.',
        goalStartDate: '2022-10-01:00:00Z',
        goalEndDate: '2022-10-19T00:00:00Z',
        goalAmount: '8409.90',
        goalProgress: '0',
        bonusAmount: '42.05',
        goalReached: false,
        optedIn: false,
        goalId: 1,
      },
    },
  };
};
